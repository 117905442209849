//Phytochemicals Data

const phytochemicals = [
  {
    id: 1,
    Image: "/products/Pellitorine-18836-52-7.jpg",
    Product: "Pellitorine",
    BotanicalSource: "Piper longum",
    IUPACName: "(2E,4E)-N-(2-Methylpropyl)-2,4-decadienamide",
    Synonyms: "trans-Pellitorin",
    CASNumber: "18836-52-7",
    MolecularFormula: "C14H25NO",
    MolecularWeight: 223.35,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C(/C=C/C=C/CCCCC)NCC(C)C",
    InChICode:
      "InChI=1S/C14H25NO/c1-4-5-6-7-8-9-10-11-14(16)15-12-13(2)3/h8-11,13H,4-7,12H2,1-3H3,(H,15,16)/b9-8+,11-10+",
    InChIKey: "MAGQQZHFHJDIRE-BNFZFUHLSA-N",
    PubChemCID: 5318516,
    MDLNumber: "MFCD01735995",
    InventoryStatus: "Available in milligrams",
  },
  {
    id: 2,
    Image: "/products/TurmeroneAr-532-65-0.jpg",
    Product: "(S)-(+)-Turmerone,Ar",
    BotanicalSource: "Curcuma longa",
    IUPACName: "2-methyl-6S-(4-methylphenyl)-2-hepten-4-one",
    Synonyms: "Ar-Turmerone",
    CASNumber: "532-65-0",
    MolecularFormula: "C15H20O",
    MolecularWeight: 216.32,
    Appearance: "Light yellow color viscous liquid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC1=CC=C([C@@H](C)CC(/C=C(C)/C)=O)C=C1",
    InChICode:
      "InChI=1S/C15H20O/c1-11(2)9-15(16)10-13(4)14-7-5-12(3)6-8-14/h5-9,13H,10H2,1-4H3/t13-/m0/s1",
    InChIKey: "NAAJVHHFAXWBOK-ZDUSSCGKSA-N",
    PubChemCID: 160512,
    MDLNumber: "MFCD09031381",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 3,
    Image: "/products/11-Keto-boswellic-acid-17019-92-0.jpg",
    Product: "11-Keto β boswellic acid",
    BotanicalSource: "Boswellia serrata",
    IUPACName:
      "(3α,4β)-3-Hydroxy-11-oxours-12-en-23-oic Acid; 3α-Hydroxy-11-oxours-12-en-24-oic Acid",
    Synonyms: "11-Oxo-β-boswellic Acid",
    CASNumber: "17019-92-0",
    MolecularFormula: "C30H46O4",
    MolecularWeight: 470.68,
    Appearance: "white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1C=C2[C@](CC[C@]3(C)C2[C@@H](C)[C@H](C)CC3)(C)[C@]4(C)CCC5[C@@](C)(C(O)=O)[C@H](O)CC[C@]5(C)C41",
    InChICode:
      "InChI=1S/C30H46O4/c1-17-8-11-26(3)14-15-28(5)19(23(26)18(17)2)16-20(31)24-27(4)12-10-22(32)30(7,25(33)34)21(27)9-13-29(24,28)6/h16-18,21-24,32H,8-15H2,1-7H3,(H,33,34)/t17-,18+,21?,22-,23?,24-,26-,27+,28-,29-,30?/m1/s1",
    InChIKey: "YIMHGPSYDOGBPI-BVIVALHHSA-N",
    PubChemCID: 9847548,
    MDLNumber: "MFCD06656312",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
    // "type":"analogs",
  },
  {
    id: 4,
    Image: "/products/12-Deoxywithastramonolide-60124-17-6.jpg",
    Product: "12-Deoxywithastramonolide",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "(5α,6α,7α,22R)-6,7-Epoxy-5,22,27-trihydroxy-1-oxoergosta-2,24-dien-26-oic acid δ-lactone",
    Synonyms: "27-Hydroxywithanolide B",
    CASNumber: "60124-17-6",
    MolecularFormula: "C28H38O6",
    MolecularWeight: 470.6,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(C1)=C(CO)C(O[C@@]1([H])[C@@H](C)[C@@]2([H])CC[C@@]3([H])[C@]4([H])[C@H]5[C@H](O5)[C@@]([C@@]6(C)[C@@]4([H])CC[C@@]32C)(O)CC=CC6=O)=O",
    InChICode:
      "InChI=1S/C28H38O6/c1-14-12-20(33-25(31)16(14)13-29)15(2)17-7-8-18-22-19(9-11-26(17,18)3)27(4)21(30)6-5-10-28(27,32)24-23(22)34-24/h5-6,15,17-20,22-24,29,32H,7-13H2,1,4,7-8,10-12H2,2-3H3/b6-5+/t15-,16+,17-,19+,20+,22-,23-,24-,26+,27-,28-/m1/s1",
    InChIKey: "AWVMHXZWAKRDGG-MEBIVHGNSA-N",
    PubChemCID: 44576309,
    MDLNumber: "MFCD03788778",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 5,
    Image: "/products/14-Deoxy-11-12-didehydroandrographolide-42895-58-9.jpg",
    Product: "14-Deoxy-11,12-didehydroandrographolide",
    BotanicalSource: "Andrographis paniculata",
    IUPACName:
      "3-[(1E)-2-[(1R,4aS,5R,6R,8aR)-decahydro-6-hydroxy-5-(hydroxymethyl)-5,8a-dimethyl-2-methylene-1-naphthalenyl]ethenyl]-2(5H)-furanone",
    Synonyms: "14-dehydro Andrographolide",
    CASNumber: "42895-58-9",
    MolecularFormula: "C20H28O4",
    MolecularWeight: 332.43,
    Appearance: "white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C=C1CC[C@]2([H])[C@@](CO)(C)[C@H](O)CC[C@@]2(C)[C@@H]1/C=C/C3=CCOC3=O",
    InChICode:
      "InChI=1S/C20H28O4/c1-13(4-7-16-19(2,10-8-17(22)20(16,3)12-21)15(13)6-5-14-9-11-24-18(14)23/h5-6,9,15-17,21-22H,1,4,7-8,10-12H2,2-3H3/b6-5+/t15-,16+,17-,19+,20+/m1/s1",
    InChIKey: "XMJAJFVLHDIEHF-CRBRZBHVSA-N",
    PubChemCID: 5708351,
    MDLNumber: "MFCD07778081",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 6,
    Image: "/products/2-3-Dihydrowithaferin-A-21902-96-5.jpg",
    Product: "2,3-Dihydro-3-methoxywithaferin A",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "(1S,2R,5S,6S,7R,9R,11S,12S,15R,16S)-6-hydroxy-15-[(1S)-1-[(2R)-5-(hydroxymethyl)-4-methyl-6-oxo-2,3-dihydropyran-2-yl]ethyl]-5-methoxy-2,16-dimethyl-8-oxapentacyclo[9.7.0.02,7.07,9.012,16]octadecan-3-one",
    Synonyms: "Quresimine A",
    CASNumber: "21902-96-5",
    MolecularFormula: "C29H42O7",
    MolecularWeight: 502.64,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC1=C(C(=O)OC(C1)C(C)C2CCC3C2(CCC4C3CC5C6(C4(C(=O)CC(C6O)OC)C)O5)C)CO",
    InChICode:
      "InChI=1S/C29H42O7/c1-14-10-21(35-26(33)17(14)13-30)15(2)18-6-7-19-16-11-24-29(36-24)25(32)22(34-5)12-23(31)28(29,4)20(16)8-9-27(18,19)3/h15-16,18-22,24-25,30,32H,6-13H2,1-5H3/t15-,16-,18+,19-,20-,21+,22-,24+,25-,27+,28-,29-/m0/s1",
    InChIKey: "MKTMIPAPOLDOQT-QAYSIJLNSA-N",
    PubChemCID: 10767792,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 7,
    Image: "/products/20-Hydroxyecdysone-5289-74-7.jpg",
    Product: "20-Hydroxy ecdysone",
    BotanicalSource: "Ipomoea hederacea",
    IUPACName: "(5β)-2β,3β,14,20,22R,25-hexahydroxy-cholest-7-en-6-one",
    Synonyms: "Ecdysterone",
    CASNumber: "5289-74-7",
    MolecularFormula: "C27H44O7",
    MolecularWeight: 480.63,
    Appearance: "White crystalline powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1[C@]2([H])C[C@@H](O)[C@@H](O)C[C@]2(C)[C@]3([H])C([C@@](CC[C@]4([H])[C@]([C@H](O)CCC(C)(O)C)(O)C)(O)[C@]4(C)CC3)=C1",
    InChICode:
      "InChI=1S/C27H44O7/c1-23(2,32)9-8-22(31)26(5,33)21-7-11-27(34)16-12-18(28)17-13-19(29)20(30)14-24(17,3)15(16)6-10-25(21,27)4/h12,15,17,19-22,29-34H,6-11,13-14H2,1-5H3/t15-,17-,19+,20-,21-,22+,24+,25+,26-,28-/m1/s1",
    InChIKey: "NKDFYOWSKOHCCO-YPVLXUMRSA-N",
    PubChemCID: 5459840,
    MDLNumber: "MFCD00036740",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 8,
    Image: "/products/3-Acetyl-11-keto-boswellic-acid-67416-61-9.jpg",
    Product: "3-Acetyl-11-keto βboswellic acid(AKBA)",
    BotanicalSource: "Boswellia serrata",
    IUPACName: "(3α,4β)-3-(acetyloxy)-11-oxo-urs-12-en-23-oic acid",
    Synonyms: "AKBA",
    CASNumber: "67416-61-9",
    MolecularFormula: "C32H48O5",
    MolecularWeight: 512.72,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1C=C2[C@](CC[C@]3(C)[C@@]2([H])[C@@H](C)[C@H](C)CC3)(C)[C@]4(C)CC[C@@]5([H])[C@@](C)(C(O)=O)[C@H](OC(C)=O)CC[C@]5(C)[C@]41[H]",
    InChICode:
      "InChI=1S/C32H48O5/c1-18-9-12-28(4)15-16-30(6)21(25(28)19(18)2)17-22(34)26-29(5)13-11-24(37-20(3)33)32(8,27(35)36)23(29)10-14-31(26,30)7/h17-19,23-26H,9-16H2,1-8H3,(H,35,36)/t18-,19+,23-,24-,25+,26-,28-,29+,30-,31-,32?/m1/s1",
    InChIKey: "HMMGKOVEOFBCAU-HUXDCABZSA-N",
    PubChemCID: 11168203,
    MDLNumber: "MFCD03788777",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 9,
    Image: "/products/7-Deacetylazadiradione.jpg",
    Product: "7-Deacetyl azadiradione",
    BotanicalSource: "Azadirchta indica",
    IUPACName: "",
    Synonyms: null,
    CASNumber: null,
    MolecularFormula: "C26H32O4",
    MolecularWeight: 408.6,
    Appearance: "White Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 10,
    Image: "/products/7-Deacetylazadirone.jpg",
    Product: "7-Deacetylazadirone",
    BotanicalSource: "Azadirchta indica",
    IUPACName: null,
    Synonyms: null,
    CASNumber: null,
    MolecularFormula: "C26H32O3",
    MolecularWeight: 394.6,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 11,
    Image: "/products/7-Deacetylepoxyazadiradione.jpg",
    Product: "7-Deacetyl epoxyazadiradione",
    BotanicalSource: "Azadirchta indica",
    IUPACName: null,
    Synonyms: null,
    CASNumber: null,
    MolecularFormula: "C26H32O5",
    MolecularWeight: 424.6,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 12,
    Image: "/products/7-Deacetylgedunin-10314-90-6.jpg",
    Product: "7-Deacetylgedunin",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "(1S,3aS,4aR,4bS,5R,6aR,10aR,10bR,12aS)-1-(furan-3-yl)-5-hydroxy-4b,7,7,10a,12a-pentamethyl-1,5,6,6a,7,10a,10b,11,12,12a-decahydronaphtho[2,1-f]oxireno[2,3-d]isochromene-3,8(3aH,4bH)-dione",
    Synonyms: "7-Deacetoxy-7-hydroxygedunin",
    CASNumber: "10314-90-6",
    MolecularFormula: "C26H32O6",
    MolecularWeight: 440.52,
    Appearance: "white powder",
    Purity: ">98%",
    HRMS_ESI: "m/z 440 [M]+",
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@@]12CC[C@@H]3[C@]4(C=CC(=O)C([C@@H]4C[C@H]([C@]3([C@@]15[C@H](O5)C(=O)O[C@H]2c6ccoc6)C)O)(C)C)C",
    InChICode:
      "InChI=1S/C26H32O6/c1-22(2)16-12-18(28)25(5)15(23(16,3)9-7-17(22)27)6-10-24(4)19(14-8-11-30-13-14)31-21(29)20-26(24,25)32-20/h7,9,11,13,15-16,18-20,28H,6,10,12H2,1-5H3/t15-,16+,18-,19+,20-,23-,24+,25+,26-/m1/s1",
    InChIKey: "HCEYJYMNIQHPPK-DXTZDJJUSA-N",
    PubChemCID: 3034112,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 13,
    Image: "/products/Aloe-emodin-481-72-1.jpg",
    Product: "Aloe emodin",
    BotanicalSource: "Aloe barbadensis",
    IUPACName: "1,8-dihydroxy-3-(hydroxymethyl)-9,10-anthracenedione",
    Synonyms: "3-Hydroxymethylchrysazine",
    CASNumber: "481-72-1",
    MolecularFormula: "C15H10O5",
    MolecularWeight: 270.24,
    Appearance: "white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C2=CC(CO)=CC(O)=C2C(C3=C1C=CC=C3O)=O",
    InChICode:
      "InChI=1S/C15H10O5/c16-6-7-4-9-13(11(18)5-7)15(20)12-8(14(9)19)2-1-3-10(12)17/h1-5,16-18H,6H2",
    InChIKey: "YDQWDHRMZQUTBA-UHFFFAOYSA-N",
    PubChemCID: 10207,
    MDLNumber: "MFCD00017373",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 14,
    Image: "/products/Alantolactone-546-43-0.jpg",
    Product: "Alantolactone",
    BotanicalSource: "Inula racemosa",
    IUPACName:
      "(3aR,5S,8aR,9aR)-3a,5,6,7,8,8a,9,9a-Octahydro-5,8a-dimethyl-3-methylene-naphtho[2,3-b]furan-2(3H)-one",
    Synonyms: "Alant camphor",
    CASNumber: "546-43-0",
    MolecularFormula: "C15H20O2",
    MolecularWeight: 232.32,
    Appearance: "White crystalline powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C[C@H]1CCC[C@]2(C)C[C@H]3OC(=O)C(=C)[C@H]3C=C12",
    InChICode:
      "InChI=1S/C15H20O2/c1-9-5-4-6-15(3)8-13-11(7-12(9)15)10(2)14(16)17-13/h7,9,11,13H,2,4-6,8H2,1,3H3/t9-,11+,13+,15+/m1/s1",
    InChIKey: "PXOYOCNNSUAQNS-AGNJHWRGSA-N",
    PubChemCID: 72724,
    MDLNumber: "MFCD00274568",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 15,
    Image: "/products/Andrographolide-5508-58-7.jpg",
    Product: "Andrographolide",
    BotanicalSource: "Andrographis paniculata",
    IUPACName:
      "(3E,4S)-3-[2-[(1R,4aS,5R,6R,8aS)-Decahydro-6-hydroxy-5-(hydroxymethyl)-5,8a-dimethyl-2-methylene-1-naphthalenyl]ethylidene]dihydro-4-hydroxy-2(3H)-furanone",
    Synonyms: "Andrographis",
    CASNumber: "5508-58-7",
    MolecularFormula: "C20H30O5",
    MolecularWeight: 350.5,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C=C1CC[C@]2([H])[C@@](CO)(C)[C@H](O)CC[C@@]2(C)[C@@H]1/C=C/C3C(OC[C@H]3O)=O",
    InChICode:
      "InChI=1S/C20H30O5/c1-12-4-7-16-19(2,9-8-17(23)20(16,3)11-21)14(12)6-5-13-15(22)10-25-18(13)24(h7,9,11,13H,2,4-6,8H2,1-3H3/b13-5+/t14-,15-,16+,17-,19+,20-,23-,24+,25+,26-/m1/s1",
    InChIKey: "BOJKULTULYSRAS-OTESTREVSA-N",
    PubChemCID: 5318517,
    MDLNumber: "MFCD07778082",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 16,
    Image: "/products/Angelicin-523-50-2.jpg",
    Product: "Angelicin (Isopsoralen)",
    BotanicalSource: "Psoralea corylifolia",
    IUPACName: "furo[2,3-h]chromen-2-one",
    Synonyms: "Isopsoralen",
    CASNumber: "523-50-2",
    MolecularFormula: "C11H6O3",
    MolecularWeight: 186.17,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C1=CC2=C(C=CO2)C3=C1C=CC(=O)O3",
    InChICode:
      "InChI=1S/C11H6O3/c12-10-4-2-7-1-3-9-8(5-6-13-9)11(7)14-10/h1-6H",
    InChIKey: "XDROKJSWHURZGO-UHFFFAOYSA-N",
    PubChemCID: 10658,
    MDLNumber: "MFCD00064930",
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 17,
    Image: "/products/Asiatic-acid-464-92-6.jpg",
    Product: "Asiatic acid",
    BotanicalSource: "Centella asiatica",
    IUPACName: "(4α)-2α,3β,23-trihydroxy-urs-12-en-28-oic acid",
    Synonyms: "Dammarolic Acid",
    CASNumber: "464-92-6",
    MolecularFormula: "C30H48O5",
    MolecularWeight: 488.7,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "OC[C@]1(C)[C@@H](O)[C@H](O)C[C@@]2(C)[C@@]1([H])CC[C@]3(C)[C@]2([H])CC=C4[C@@]3(C)CC[C@]5(C(O)=O)[C@@]4([H])[C@@H](C)[C@H](C)CC5",
    InChICode:
      "InChI=1S/C30H48O5/c1-17-9-12-30(25(34)35)14-13-28(5)19(23(30)18(17)2)7-8-22-26(3)15-20(32)24(33)27(4,16-31)21(26)10-11-29(22,28)6/h7,17-18,20-24,31-33H,8-16H2,1-6H3,(H,34,35)/t17-,18+,20-,21-,22-,23+,24+,26+,27+,28-,29-,30?/m1/s1",
    InChIKey: "JXSVIVRDWWRQRT-DPJHZOGISA-N",
    PubChemCID: 119034,
    MDLNumber: "MFCD00238541",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 18,
    Image: "/products/Asiaticoside-16830-15-2.jpg",
    Product: "Asiaticoside",
    BotanicalSource: "Centella asiatica",
    IUPACName:
      "2α,3β,23-trihydroxy-urs-12-en-28-oic acid, O-6-deoxy-α-L-mannopyranosyl-(1→4α)-O-β-D-glucopyranosyl-(1→6)-β-D-glucopyranosyl ester",
    Synonyms: "Madecassol",
    CASNumber: "16830-15-2",
    MolecularFormula: "C48H78O19",
    MolecularWeight: 959.12,
    Appearance: "White crystalline solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "OC[C@]1(C)[C@@H](O)[C@H](O)C[C@@]2(C)[C@@]1([H])CC[C@]3(C)[C@]2([H])CC=C4[C@@]3(C)CC[C@]5(C(O[C@@H]6O[C@H](CO[C@H]7[C@H](O)[C@@H](O)[C@H](O[C@@]8([H])[C@H](O)[C@H](O)[C@@H](O)[C@H](C)O8)[C@@H](CO)O7)[C@@H](O)[C@H](O)[C@H]6O)=O)[C@@]4([H])[C@@H](C)[C@H](C)CC5",
    InChICode:
      "InChI=1S/C48H78O19/c1-20-10-13-48(15-14-46(6)23(29(48)21(20)2)8-9-28-44(4)16-24(51)39(60)45(5,19-50)27(44)11-12-47(28,46)7)43(61)67-42-36(58)33(55)31(53)26(65-42)18-62-40-37(59)34(56)38(25(17-49)64-40)66-41-35(57)32(54)30(52)22(3)63-41/h8,20-22,24-42,49-60H,9-19H2,1-7H3/t20-,21+,22+,24-,25-,26-,27-,28-,29+,30+,31-,32-,33+,34-,35-,36-,37-,38-,39+,40-,41+,42+,44+,45+,46-,47-,48+/m1/s1",
    InChIKey: "WYQVAPGDARQUBT-FGWHUCSPSA-N",
    PubChemCID: 11954171,
    MDLNumber: "MFCD06642601",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 19,
    Image: "/products/Azadiradione-26241-51-0.jpg",
    Product: "Azadiradione",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "[(5R,7R,8R,9R,10R,13S,17R)-17-(furan-3-yl)-4,4,8,10,13-pentamethyl-3,16-dioxo-6,7,9,11,12,17-hexahydro-5H-cyclopenta[a]phenanthren-7-yl] acetate",
    Synonyms: "NA",
    CASNumber: "26241-51-0",
    MolecularFormula: "C28H34O5",
    MolecularWeight: 450.6,
    Appearance: "white powder",
    Purity: ">98%",
    HRMS_ESI: "m/z 451.2 [M]+",
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=O)O[C@@H]1C[C@@H]2[C@](C=CC(=O)C2(C)C)([C@@H]3[C@@]1(C4=CC(=O)[C@H]([C@@]4(CC3)C)C5=COC=C5)C)C",
    InChICode:
      "InChI=1S/C28H34O5/c1-16(29)33-23-14-20-25(2,3)22(31)8-11-26(20,4)19-7-10-27(5)21(28(19,23)6)13-18(30)24(27)17-9-12-32-15-17/h8-9,11-13,15,19-20,23-24H,7,10,14H2,1-6H3/t19-,20+,23-,24-,26-,27-,28-/m1/s1",
    InChIKey: "KWAMDQVQFVBEAU-HMWIRDDCSA-N",
    PubChemCID: 12308714,
    MDLNumber: "MFCD16036226",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 20,
    Image: "/products/Azadirone-25279-67-8.jpg",
    Product: "Azadirone",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "(5alpha,7alpha,13alpha,17alpha)-17-(furan-3-yl)-4,4,8-trimethyl-3-oxoandrosta-1,14-dien-7-yl acetate",
    Synonyms: null,
    CASNumber: "25279-67-8",
    MolecularFormula: "C28H36O4",
    MolecularWeight: 436.6,
    Appearance: "White crystalline powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC(=O)OC1CC2C(C(=O)C=CC2(C3C1(C4=CCC(C4(CC3)C)C)C)C)(C)C",
    InChICode:
      "InChI=1S/C28H36O4/c1-17(29)32-24-15-22-25(2,3)23(30)10-13-27(22,5)21-9-12-26(4)19(18-11-14-31-16-18)7-8-20(26)28(21,24)6/h8,10-11,13-14,16,19,21-22,24H,7,9,12,15H2,1-6H3/t19-,21+,22-,24+,26-,27+,28-/m0/s1",
    InChIKey: "XXIKKMLIDXLAIK-RFKFVWFBSA-N",
    PubChemCID: 10906239,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },

  {
    id: 21,
    Image: "/products/Bakuchiol-10309-37-2.jpg",
    Product: "Bakuchiol",
    BotanicalSource: "Psoralea corylifolia",
    IUPACName: "4-[(1E,3S)-3-Ethenyl-3,7-dimethyl-1,6-octadien-1-yl]-phenol",
    Synonyms: "(S)-(+)-Bakuchiol",
    CASNumber: "10309-37-2",
    MolecularFormula: "C₁₈H₂₄O",
    MolecularWeight: 256.38,
    Appearance: "orange red color viscous liquid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=CC=C(/C=C/[C@](C)(C=C)CC/C=C(C)C)C=C1",
    InChICode:
      "InChI=1S/C18H24O/c1-5-18(4,13-6-7-15(2)3)14-12-16-8-10-17(19)11-9-16/h5,7-12,14,19H,1,6,13H2,2-4H3/b14-12+/t18-/m1/s1",
    InChIKey: "LFYJSSARVMHQJB-QIXNEVBVSA-N",
    PubChemCID: 5468522,
    MDLNumber: "MFCD01707441",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 22,
    Image: "/products/Bisdemethoxycurcumin-24939-16-0.jpg",
    Product: "Bisdemethoxycurcumin",
    BotanicalSource: "Curcuma longa",
    IUPACName: "1,7-Bis(4-hydroxyphenyl)-1,6-heptadiene-3,5-dione",
    Synonyms: null,
    CASNumber: "24939-16-0",
    MolecularFormula: "C19H16O4",
    MolecularWeight: 308.33,
    Appearance: "Yellow orange colored powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=CC=C(/C=C/C(CC(/C=C/C2=CC=C(O)C=C2)=O)=O)C=C1",
    InChICode:
      "InChI=1S/C19H16O4/c20-16-7-1-14(2-8-16)5-11-18(22)13-19(23)12-6-15-3-9-17(21)10-4-15/h1-12,20-21H,13H2/b11-5+,12-6+",
    InChIKey: "PREBVFJICNPEKM-YDWXAUTNSA-N",
    PubChemCID: 5315472,
    MDLNumber: "MFCD03419284",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 23,
    Image: "/products/Camptothecine-7689-03-4.jpg",
    Product: "Camptothecine",
    BotanicalSource: "Mappia foetida",
    IUPACName:
      "(4S)-4-ethyl-4-hydroxy-1H-pyrano[3',4':6,7]indolizino[1,2-b]quinoline-3,14(4H,12H)-dione",
    Synonyms: null,
    CASNumber: "7689-03-4",
    MolecularFormula: "C20H16N2O4",
    MolecularWeight: 348.35,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C([C@@]1(CC)O)OCC2=C1C=C(C(N=C(C=CC=C3)C3=C4)=C4C5)N5C2=O",
    InChICode:
      "InChI=1S/C20H16N2O4/c1-2-20(25)14-8-16-17-12(7-11-5-3-4-6-15(11)21-17)9-22(16)18(23)13(14)10-26-19(20)24/h3-8,25H,2,9-10H2,1-5H3/b7-3+,8-4+",
    InChIKey: "VSJKWCGYPAHWDS-FQEVSTJZSA-N",
    PubChemCID: 24360,
    MDLNumber: "MFCD00081076",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 24,
    Image: "/products/Conessine-546-06-5.jpg",
    Product: "Conessine",
    BotanicalSource: "Holarrhena antidysenterica",
    IUPACName: "(3β)-N,N-dimethyl-con-5-enin-3-amine",
    Synonyms: "Wrightine",
    CASNumber: "546-06-5",
    MolecularFormula: "C24H40N2",
    MolecularWeight: 356.59,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@H]1[C@@]2([H])CC[C@@]3([H])[C@]4([H])CC=C5C[C@@H](N(C)C)CC[C@]5(C)[C@@]4([H])CC[C@@]32CN1C",
    InChICode:
      "InChI=1S/C24H40N2/c1-16-20-8-9-22-19-7-6-17-14-18(25(3)4)10-12-23(17,2)21(19)11-13-24(20,22)15-26(16)5/h6,16,18-22H,7-15H2,1-5H3/t16-,18-,19+,20+,21-,22-,23-,24-/m0/s1",
    InChIKey: "GPLGAQQQNWMVMM-MYAJQUOBSA-N",
    PubChemCID: 441082,
    MDLNumber: "MFCD00016752",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 25,
    Image: "/products/Curcumin-458-37-7.jpg",
    Product: "Curcumin",
    BotanicalSource: "Curcuma longa",
    IUPACName:
      "(E,E)-1,7-bis(4-Hydroxy-3-methoxyphenyl)-1,6-heptadiene-3,5-dione",
    Synonyms: "Diferuloylmethane",
    CASNumber: "458-37-7",
    MolecularFormula: "C21H20O6",
    MolecularWeight: 368.38,
    Appearance: "Yellow colored powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COc1cc(/C=C/C(=O)CC(=O)/C=C/c2ccc(O)c(OC)c2)ccc1O",
    InChICode:
      "InChI=1S/C21H20O6/c1-26-20-11-14(5-9-18(20)24)3-7-16(22)13-17(23)8-4-15-6-10-19(25)21(12-15)27-2/h3-12,24-25H,13H2,1-2H3/b7-3+,8-4+",
    InChIKey: "VFLDPWHFBUODDF-FCXRPNKRSA-N",
    PubChemCID: 969516,
    MDLNumber: "MFCD00008365",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 26,
    Image: "/products/Demethoxycurcumin-22608-11-3.jpg",
    Product: "Demethoxycurcumin",
    BotanicalSource: "Curcuma longa",
    IUPACName:
      "(E,E)-1-(4-Hydroxy-3-methoxyphenyl)-7-(4-hydroxyphenyl)-1,6-heptadiene-3,5-dione",
    Synonyms: "Desmethoxycurcumin",
    CASNumber: "22608-11-3",
    MolecularFormula: "C20H18O5",
    MolecularWeight: 338.35,
    Appearance: "Yellow colored powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=CC=C(/C=C/C(CC(/C=C/C2=CC(OC)=C(O)C=C2)=O)=O)C=C1",
    InChICode:
      "InChI=1S/C20H18O5/c1-25-20-12-15(6-11-19(20)24)5-10-18(23)13-17(22)9-4-14-2-7-16(21)8-3-14/h2-12,21,24H,13H2,1H3/b9-4+,10-5+",
    InChIKey: "HJTVQHVGMGKONQ-LUZURFALSA-N",
    PubChemCID: 5469424,
    MDLNumber: "MFCD03427310",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 27,
    Image: "/products/Demethylwedelolactone-6468-55-9.jpg",
    Product: "Demethylwedelolactone",
    BotanicalSource: "Eclipta alba",
    IUPACName: "1,3,8,9-Tetrahydroxy-6H-benzofuro[3,2-c][1]benzopyran-6-one",
    Synonyms: "Desmethylwedelolactone",
    CASNumber: "6468-55-9",
    MolecularFormula: "C15H8O7",
    MolecularWeight: 300.22,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=CC2=C(OC(C(C(O)=CC(O)=C3)=C3O4)=C2C4=O)C=C1O",
    InChICode:
      "InChI=1S/C15H8O7/c16-5-1-9(19)13-11(2-5)22-15(20)12-6-3-7(17)8(18)4-10(6)21-14(12)13/h1-4,16-19H",
    InChIKey: "LUTYTNLPIUCKBJ-UHFFFAOYSA-N",
    PubChemCID: 5489605,
    MDLNumber: "MFCD09264645",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 28,
    Image: "/products/Epoxyazadiradione-18385-59-6.jpg",
    Product: "Epoxyazadiradione",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "[(1S,2R,4S,6S,7S,10R,11R,16R,18R)-6-(furan-3-yl)-1,7,11,15,15-pentamethyl-5,14-dioxo-3-oxapentacyclo[8.8.0.02,4.02,7.011,16]octadec-12-en-18-yl] acetate",
    Synonyms: "Nimbinin",
    CASNumber: "18385-59-6",
    MolecularFormula: "C28H34O6",
    MolecularWeight: 466.6,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=O)O[C@@H]1C[C@@H]2[C@](C=CC(=O)C2(C)C)([C@@H]3[C@@]1([C@]45[C@H](O4)C(=O)[C@H]([C@@]5(CC3)C)C6=COC=C6)C)C",
    InChICode:
      "InChI=1S/C28H34O6/c1-15(29)33-20-13-18-24(2,3)19(30)8-10-25(18,4)17-7-11-26(5)21(16-9-12-32-14-16)22(31)23-28(26,34-23)27(17,20)6/h8-10,12,14,17-18,20-21,23H,7,11,13H2,1-6H3/t13-,15-,16-,17,19-,20-,21+,23+,25+,26+,27+,28-/m1/s1",
    InChIKey: "NEYCGDYQBQONFC-GGPFZBFUSA-N",
    PubChemCID: 49863985,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 29,
    Image: "/products/Forskolin-66575-29-9.jpg",
    Product: "Forskolin",
    BotanicalSource: "Coleus forskohlii",
    IUPACName: "7β-Acetoxy-8,13-epoxy-1α,6β,9α-trihydroxylabd-14-en-11-one",
    Synonyms: "Coleonol",
    CASNumber: "66575-29-9",
    MolecularFormula: "C22H34O7",
    MolecularWeight: 410.5,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@@](C1)(C=C)O[C@]2(C)[C@@H](OC(C)=O)[C@@H](O)[C@@]3([H])C(C)(C)CC[C@H](O)[C@]3(C)[C@@]2(O)C1=O",
    InChICode:
      "InChI=1S/C22H34O7/c1-8-19(5)11-14(25)22(27)20(6)13(24)9-10-18(3,4)16(20)15(26)17(28-12(2)23)21(22,7)29-19/h8,13,15-17,24,26-27H,1,9-11H2,2-7H3/t13-,15-,16-,17,19-,20-,21+,22-/m1/s1",
    InChIKey: "OHCQJHSOBUTRHG-KGGHGJDLSA-N",
    PubChemCID: 47936,
    MDLNumber: "MFCD00082317",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 30,
    Image: "/products/Gedunin-2753-30-2.jpg",
    Product: "Gedunin",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "(1S,3aS,4aR,4bS,5R,6aR,10aR,10bR,12aS)-5-(acetyloxy)-1-(3-furanyl)-1,5,6,6a,7,10a,10b,11,12,12a-decahydro-4b,7,7,10a,12a-pentamethyl-oxireno[c]phenanthro[1,2-d]pyran-3,8(3aH,4bH)-dione",
    Synonyms: "NSC 113497",
    CASNumber: "2753-30-2",
    MolecularFormula: "C28H34O7",
    MolecularWeight: 482.57,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: "m/z 483.23 [M]+",
    SpecificRotation_aD20: null,
    SMILES:
      "O=C4[C@@H]6[C@]3(O6)[C@]2(C)[C@H](OC(C)=O)C[C@@]1([H])C(C)(C)C(C=C[C@@](C)1[C@]([H])2CC[C@](C)3[C@H]([C@]5=COC=C5)O4)=O",
    InChICode:
      "InChI=1S/C28H34O7/c1-15(29)33-20-13-18-24(2,3)19(30)8-10-25(18,4)17-7-11-26(5)21(16-9-12-32-14-16)34-23(31)22-28(26,35-22)27(17,20)6/h8-10,12,14,17-18,20-22H,7,11,13H2,1-6H3/t17-,18+,20-,21+,22-,25-,26+,27+,28-/m1/s1",
    InChIKey: "YJXDGWUNRYLINJ-BHAPSIHVSA-N",
    PubChemCID: 12004512,
    MDLNumber: "MFCD01750174",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 31,
    Image: "/products/Guggulsterone-E-39025-24-6.jpg",
    Product: "Guggulsterone-E",
    BotanicalSource: "Commiphora mukul",
    IUPACName: "(17E)-Pregna-4,17(20)-diene-3,16-dione",
    Synonyms: "Trans-Guggulsterone",
    CASNumber: "39025-24-6",
    MolecularFormula: "C21H28O2",
    MolecularWeight: 312.45,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1CC[C@@]2(C)C(CC[C@]3([H])[C@]2([H])CC[C@@]4(C)[C@@]3([H])CC(/C4=CC)=O)=C1",
    InChICode:
      "InChI=1S/C21H28O2/c1-4-16-19(23)12-18-15-6-5-13-11-14(22)7-9-20(13,2)17(15)8-10-21(16,18)3/h4,11,15,17-18,20-21,23H,5-10,12H2,1-3H3/b16-4+/t15-,17+,18+,20+,21-/m1/s1",
    InChIKey: "WDXRGPWQVHZTQJ-OSJVMJFVSA-N",
    PubChemCID: 6439929,
    MDLNumber: "MFCD01310758",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 32,
    Image: "/products/Guggulsterone-Z-39025-23-5.jpg",
    Product: "Guggulsterone-Z",
    BotanicalSource: "Commiphora mukul",
    IUPACName: "(17Z)-Pregna-4,17(20)-diene-3,16-dione",
    Synonyms: null,
    CASNumber: "39025-23-5",
    MolecularFormula: "C21H28O2",
    MolecularWeight: 312.45,
    Appearance: "White amorphous powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C/C=C1\\C(=O)CC2C3CCC4=CC(=O)CC[C@]4(C)C3CC[C@]\\12C",
    InChICode:
      "InChI=1S/C21H28O2/c1-4-16-19(23)12-18-15-6-5-13-11-14(22)7-9-20(13,2)17(15)8-10-21(16,18)3/h4,11,15,17-18H,5-10,12H2,1-3H3/b16-4+/t15?,17?,18?,20-,21+/m0/s1",
    InChIKey: "WDXRGPWQVHZTQJ-XCTJHIRPSA-N",
    PubChemCID: 6450278,
    MDLNumber: "MFCD01310757",
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 33,
    Image: "/products/Hypophyllanthin-33676-00-5.jpg",
    Product: "Hypophyllanthin",
    BotanicalSource: "Phyllanthus amarus",
    IUPACName:
      "(7S,8S,9R)-9-(3,4-dimethoxyphenyl)-6,7,8,9-tetrahydro-4-methoxy-7,8-bis(methoxymethyl)-naphtho[1,2-d]-1,3-dioxole",
    Synonyms: "NSC 619044",
    CASNumber: "33676-00-5",
    MolecularFormula: "C24H30O7",
    MolecularWeight: 430.49,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "COC[C@@H]1[C@@H](COC)CC(C=C(OC)C2=C3OCO2)=C3[C@H]1C4=CC(OC)=C(OC)C=C4",
    InChICode:
      "InChI=1S/C24H30O7/c1-25-11-16-8-15-10-20(29-5)23-24(31-13-30-23)22(15)21(17(16)12-26-2)14-6-7-18(27-3)19(9-14)28-4/h6-7,9-10,16-17,21H,8,11-13H2,1-5H3/t16-,17-,21+/m1/s1",
    InChIKey: "LBJCUHLNHSKZBW-LZJOCLMNSA-N",
    PubChemCID: 182140,
    MDLNumber: "MFCD03424459",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 34,
    Image: "/products/Isoalantolactone-470-17-7.jpg",
    Product: "Isoalantolactone",
    BotanicalSource: "Inula racemosa",
    IUPACName:
      "(3aR,4aS,8aR,9aR)-decahydro-8a-methyl-3,5-bis(methylene)-naphtho[2,3-b]furan-2(3H)-one",
    Synonyms: "(+)-Isoalantolactone",
    CASNumber: "470-17-7",
    MolecularFormula: "C15H20O2",
    MolecularWeight: 232.32,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "C[C@]12[C@@](C(CCC2)=C)([H])C[C@@]3([H])[C@@](OC(C3=C)=O)([H])C1",
    InChICode:
      "InChI=1S/C15H20O2/c1-9-5-4-6-15(3)8-13-11(7-12(9)15)10(2)14(16)17-13/h11-13H,1-2,4-8H2,3H3/t11-,12+,13-,15-/m1/s1",
    InChIKey: "CVUANYCQTOGILD-QVHKTLOISA-N",
    PubChemCID: 73285,
    MDLNumber: "MFCD08689940",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 35,
    Image: "/products/Isoandrographolide-0.jpg",
    Product: "Isoandrographolide",
    BotanicalSource: "Andrographis paniculata",
    IUPACName:
      "3-[(3aR,5aS,6R,7R,9aR,9bS)-7-hydroxy-6-(hydroxymethyl)-3a,6,9a-trimethyl-dodecahydronaphtho[2,1-b]furan-2-yl]-2,5-dihydrofuran-2-one",
    Synonyms: null,
    CASNumber: null,
    MolecularFormula: "C20H30O5",
    MolecularWeight: 350.45,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@@]12CC[C@H]3[C@@](C)(CC[C@@H](O)[C@@]3(C)CO)[C@@H]1CC(O2)C1=CCOC1=O",
    InChICode:
      "InChI=1S/C20H30O5/c1-18-7-5-16(22)19(2,11-21)14(18)4-8-20(3)15(18)10-13(25-20)12-6-9-24-17(12)23/h6,13-16,21-22H,4-5,7-11H2,1-3H3/t13?,14-,15-,16+,18+,19-,20+/m0/s1",
    InChIKey: "QTYVPMSAPQBXMM-BXTHMLGCSA-N",
    PubChemCID: 101243415,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 36,
    Image: "/products/Lupeol-545-47-1.jpg",
    Product: "Lupeol",
    BotanicalSource: "Crataeva nurvala",
    IUPACName: "20(29)-Lupen-3β-ol, 3β-Hydroxy-20(29)-lupene, Fagarasterol",
    Synonyms:
      "Clerodol; Fagarasterol; Lupenol; Monogynol B; NSC 90487; β-Viscol",
    CASNumber: "545-47-1",
    MolecularFormula: "C30H50O",
    MolecularWeight: 426.72,
    Appearance: "White powder",
    Purity: ">98 %",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC1(C)[C@@H](O)CC[C@]2(C)[C@@]3([H])CC[C@]4([H])[C@@]5([H])[C@H](C(C)=C)CC[C@@](C)5CC[C@](C)4[C@@](C)3CC[C@@]12[H]",
    InChICode:
      "InChI=1S/C30H50O/c1-19(2)20-11-14-27(5)17-18-29(7)21(25(20)27)9-10-23-28(6)15-13-24(31)26(3,4)22(28)12-16-30(23,29)8/h20-25,31H,1,9-18H2,2-8H3/t20-,21+,22-,23+,24-,25+,27+,28-,29+,30+/m0/s1",
    InChIKey: "MQYXUWHLBZFQQO-QGTGJCAVSA-N",
    PubChemCID: 259846,
    MDLNumber: "MFCD00017351",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 37,
    Image: "/products/Lysergol-602-85-7.jpg",
    Product: "Lysergol",
    BotanicalSource: "Ipomoea hederacea",
    IUPACName: "9,10-didehydro-6-methyl-ergoline-8β-methanol",
    Synonyms: "Lysergole",
    CASNumber: "602-85-7",
    MolecularFormula: "C16H18N2O",
    MolecularWeight: 254.3,
    Appearance: "Off white solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CN1[C@](C2=C[C@@H](CO)C1)([H])CC3=CNC4=CC=CC2=C34",
    InChICode:
      "InChI=1S/C16H18N2O/c1-18-8-10(9-19)5-13-12-3-2-4-14-16(12)11(7-17-14)6-15(13)18/h2-5,7,10,15,17,19H,6,8-9H2,1H3/t10-,15-/m1/s1",
    InChIKey: "BIXJFIJYBLJTMK-MEBBXXQBSA-N",
    PubChemCID: 14987,
    MDLNumber: "MFCD00010029",
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 38,
    Image: "/products/Madecassicacid-18449-41-7.jpg",
    Product: "Madecassic acid",
    BotanicalSource: "Centella asiatica",
    IUPACName: "(2α,3β,4α,6β)-2,3,6,23-tetrahydroxy-urs-12-en-28-oic acid",
    Synonyms: "Brahmic acid, 6βHydroxyasiatic acid",
    CASNumber: "18449-41-7",
    MolecularFormula: "C30H48O6",
    MolecularWeight: 504.7,
    Appearance: "Crystalline solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "OC[C@]1(C)[C@@H](O)[C@H](O)C[C@@]2(C)[C@@]1([H])[C@H](O)C[C@]3(C)[C@]2([H])CC=C4[C@@]3(C)CC[C@]5(C(O)=O)[C@@]4([H])[C@@H](C)[C@H](C)CC5",
    InChICode:
      "InChI=1S/C30H48O6/c1-16-9-10-30(25(35)36)12-11-28(5)18(22(30)17(16)2)7-8-21-26(3)13-20(33)24(34)27(4,15-31)23(26)19(32)14-29(21,28)6/h7,16-17,19-24,31-34H,8-15H2,1-6H3,(H,35,36)/t16-,17+,18+,19-,20+,22+,23-,24+,26-,27+,28-,29-,30?/m1/s1",
    InChIKey: "PRAUVHZJPXOEIF-QUKJEOBDSA-N",
    PubChemCID: 73412,
    MDLNumber: "MFCD11559128",
    InventoryStatus: "Available in gram quantities",
    type: "analogs",
  },
  {
    id: 39,
    Image: "/products/Madecassoside-34540-22-2.jpg",
    Product: "Madecassoside",
    BotanicalSource: "Centella asiatica",
    IUPACName:
      "(2α,3β,4α,6β)-2,3,6,23-tetrahydroxy-urs-12-en-28-oic acid-O-6-deoxy-α-L-mannopyranosyl-(1→4)-O-β-D-glucopyranosyl-(1→6)-β-D-glucopyranosyl ester",
    Synonyms: "Asiaticoside A",
    CASNumber: "34540-22-2",
    MolecularFormula: "C48H78O20",
    MolecularWeight: 975.12,
    Appearance: "Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C(C1(CCC(C)C2C)C2C3=CCC4C(CC(O)C5C4(CC(O)C(O)C5(C)CO)C)(C)C3(C)CC1)OC(C(C(O)C6O)O)OC6COC(C(C(O)C7OC(OC(C)C(O)C8O)C8O)O)OC7CO",
    InChICode:
      "InChI=1S/C48H78O20/c1-19-10-21-23-15-31(18-39(58)62-37-25(50)20-12-29(55)46(3,4)38(20)37)45-35(23)33-41(60)43(68-45)67-42-34(26(51)21)40(59)44(69-42)66-28-11-24(49)32(57)14-22(28)13-30(56)47(5,6)36(33)16-27(52)19-48(8,9)61-27/h11,16,20-21,24-45,49-51,55-60H,12-15,17-19H2,1-10H3/t20-,21+,24-,25+,26+,27+,28-,29-,30+,31-,32+,33+,34-,35-,36+,37+,38-,39-,40+,41-,42+,43+,44+,45+,47-,48?/m1/s1",
    InChIKey: "BNMGUJRJUUDLHW-UHFFFAOYSA-N",
    PubChemCID: 45356919,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 40,
    Image: "/products/MuristeroneA-38778-30-2.jpg",
    Product: "Muristerone A",
    BotanicalSource: "Ipomoea hederacea",
    IUPACName: "2β,3β,5β,11α,14,20,22R-heptahydroxy-cholest-7-en-6-one",
    Synonyms: "Mur A",
    CASNumber: "38778-30-2",
    MolecularFormula: "C27H44O8",
    MolecularWeight: 496.64,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(C)CC[C@@H](O)[C@](C)(O)[C@@]1([H])CC[C@@]2(O)C3=CC([C@]4(O)C[C@@H](O)[C@@H](O)C[C@]4(C)[C@@]3([H])[C@H](O)C[C@@]21C)=O",
    InChICode:
      "InChI=1S/C27H44O8/c1-14(2)6-7-20(31)25(5,33)19-8-9-26(34)15-10-21(32)27(35)13-17(29)16(28)11-24(27,4)22(15)18(30)12-23(19,26)3/h10,14,16-20,22,28-31,33-35H,6-9,11-13H2,1-5H3/t16-,17-,18+,19-,20+,22+,23-,24+,25+,26+,27+/m0/s1",
    InChIKey: "LRJUYAVTHIEHAI-LHBNDURVSA-N",
    PubChemCID: 122217,
    MDLNumber: "MFCD00056450",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 41,
    Image: "/products/Neoandrographolide-27215-14-1.jpg",
    Product: "Neoandrographolide",
    BotanicalSource: "Andrographis paniculata",
    IUPACName:
      "ent-19-Hydroxy-8(17),13-labdadien-16,15-olide 19-O-β-D-glucopyranoside",
    Synonyms: "Neoandrographiside",
    CASNumber: "27215-14-1",
    MolecularFormula: "C26H40O8",
    MolecularWeight: 480.6,
    Appearance: "White solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C[C@]12[C@@](CCC([C@H]2CCC3=CCOC3=O)=C)([H])[C@@](CO[C@@H]4O[C@H](CO)[C@@H](O)[C@H](O)[C@H]4O)(C)CCC1",
    InChICode:
      "InChI=1S/C26H40O8/c1-15-5-8-19-25(2,14-33-24-22(30)21(29)20(28)18(13-27)34-24)10-4-11-26(19,3)17(15)7-6-16-9-12-32-23(16)31/h9,17-22,24,27-30H,1,4-8,10-14H2,2-3H3/t17-,18-,19-,20-,21+,22-,24-,25+,26+,27+/m1/s1",
    InChIKey: "YGCYRQKJYWQXHG-RDNQFMDVSA-N",
    PubChemCID: 9848024,
    MDLNumber: "MFCD07779130",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 42,
    Image: "/products/Nimbolide-25990-37-8.jpg",
    Product: "Nimbolide",
    BotanicalSource: "Azadirachta indica",
    IUPACName:
      "(4α,5α,6α,7α,15β,17α)-7,15:21,23-Diepoxy-6-hydroxy-4,8-dimethyl-1-oxo-18,24-dinor-11,12-secochola-2,13,20,22-tetraene-4,11-dicarboxylic Αcid γ-lactone Methyl Ester",
    Synonyms: "NSC 309909",
    CASNumber: "25990-37-8",
    MolecularFormula: "C27H30O7",
    MolecularWeight: 466.52,
    Appearance: "Off white powder",
    Purity: ">98 %",
    HRMS_ESI: "m/z 467.20 [M]+",
    SpecificRotation_aD20: null,
    SMILES:
      "CC1=C2[C@](CC1C3=COC=C3)([H])O[C@]([C@]2(C)[C@@H]4CC(OC)=O)([H])[C@@]5([H])C6[C@]4(C)C(C=C[C@@]6(C)C(O5)=O)=O",
    InChICode:
      "InChI=1S/C27H30O7/c1-13-15(14-7-9-32-12-14)10-16-20(13)27(4)17(11-19(29)31-5)26(3)18(28)6-8-25(2)22(26)21(23(27)33-16)34-24(25)30/h6-9,12,15-17,21-23H,10-11,13-15H2,1-5H3/t15?,16-,17-,21-,22?,23-,25-,26+,27-/m1/s1",
    InChIKey: "JZIQWNPPBKFOPT-FTCZZEDLSA-N",
    PubChemCID: 100017,
    MDLNUMBER: "MFCD01735386",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 43,
    Image: "/products/Niranthin-50656-77-4.jpg",
    Product: "Niranthin",
    BotanicalSource: "Phyllanthus amarus",
    IUPACName:
      "6-[(2R,3R)-3-[(3,4-dimethoxyphenyl)methyl]-4-methoxy-2-(methoxymethyl)butyl]-4-methoxy-1,3-benzodioxole",
    Synonyms: null,
    CASNumber: "50656-77-4",
    MolecularFormula: "C24H32O7",
    MolecularWeight: 432.51,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COC1=C(OCO2)C2=CC(C[C@H](COC)[C@@H](COC)CC3=CC=C(OC)C(OC)=C3)=C1",
    InChICode:
      "1S/C24H32O7/c1-25-13-18(8-16-6-7-20(27-3)21(10-16)28-4)19(14-26-2)9-17-11-22(29-5)24-23(12-17)30-15-31-24/h6-7,10-12,18-19H,8-9,13-15H2,1-5H3/t18-,19-/m0/s1",
    InChIKey: "RCFGIEPQSDGMJJ-OALUTQOASA-N",
    PubChemCID: 13989915,
    MDLNumber: "MFCD09970365",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 44,
    Image: "/products/Phyllanthin-10351-88-9.jpg",
    Product: "Phyllanthin",
    BotanicalSource: "Phyllanthus amarus",
    IUPACName:
      "1,1’-[(2S,3S)-2,3-bis(methoxymethyl)-1,4-butanediyl]bis[3,4-dimethoxy-benzene",
    Synonyms: "NSC 619043",
    CASNumber: "10351-88-9",
    MolecularFormula: "C24H34O6",
    MolecularWeight: 418.52,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "COC1=C(OC)C=C(C[C@]([C@@](CC2=CC=C(OC)C(OC)=C2)([H])COC)([H])COC)C=C1",
    InChICode:
      "InChI=1S/C24H34O6/c1-25-15-19(11-17-7-9-21(27-3)23(13-17)29-5)20(16-26-2)12-18-8-10-22(28-4)24(14-18)30-6/h7-10,13-14,19-20H,11-12,15-16H2,1-6H3/t19-,20-/m1/s1",
    InChIKey: "KFLQGJQSLUYUBF-WOJBJXKFSA-N",
    PubChemCID: 358901,
    MDLNumber: "MFCD17166965",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 45,
    Image: "/products/Piperin-94-62-2.jpg",
    Product: "Piperin",
    BotanicalSource: "Piper nigrum",
    IUPACName:
      "(2E,4E)-5-(1,3-benzodioxol-5-yl)-1-(1-piperidinyl)-2,4-pentadien-1-one",
    Synonyms: "Bioperine",
    CASNumber: "94-62-2",
    MolecularFormula: "C17H19NO3",
    MolecularWeight: 285.34,
    Appearance: "White crystalline powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C(/C=C/C=C/C1=CC(OCO2)=C2C=C1)N3CCCCC3",
    InChICode:
      "InChI=1S/C17H19NO3/c19-17(18-10-4-1-5-11-18)7-3-2-6-14-8-9-15-16(12-14)21-13-20-15/h2-3,6-9,12H,1,4-5,10-11,13H2/b6-2+,7-3+",
    InChIKey: "MXXWOMGUGJBKIW-YPCIICBESA-N",
    PubChemCID: 638024,
    MDLNumber: "MFCD00005839",
    InventoryStatus: "Available in gram quantities",
    type: "analogs",
  },
  {
    id: 46,
    Image: "/products/Piperlongumine-20069-09-4.jpg",
    Product: "Piperlongumine",
    BotanicalSource: "Piper longum",
    IUPACName:
      "5,6-dihydro-1-[(2E)-1-oxo-3-(3,4,5-trimethoxyphenyl)-2-propen-1-yl]-2(1H)-pyridinone",
    Synonyms: "Piplartine",
    CASNumber: "20069-09-4",
    MolecularFormula: "C17H19NO5",
    MolecularWeight: 317.34,
    Appearance: "Off White solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COC(C(OC)=C1)=C(OC)C=C1/C=C/C(N2C(C=CCC2)=O)=O",
    InChICode:
      "InChI=1S/C17H19NO5/c1-21-13-10-12(11-14(22-2)17(13)23-3)7-8-16(20)18-9-5-4-6-15(18)19/h4,6-8,10-11H,5,9H2,1-3H3/b8-7+",
    InChIKey: "VABYUUZNAVQNPG-BQYQJAHWSA-N",
    PubChemCID: 637858,
    MDLNumber: "MFCD00075706",
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 47,
    Image: "/products/Piperlonguminine-5950-12-9.jpg",
    Product: "Piperlonguminine",
    BotanicalSource: "Piper longum",
    IUPACName:
      "(2E,4E)-5-(1,3-benzodioxol-5-yl)-N-(2-methylpropyl)penta-2,4-dienamide",
    Synonyms: "N-Isobutylpiperamide",
    CASNumber: "5950-12-9",
    MolecularFormula: "C16H19NO3",
    MolecularWeight: 273.33,
    Appearance: "White solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC(C)CNC(=O)C=CC=CC1=CC2=C(C=C1)OCO2",
    InChICode:
      "InChI=1S/C16H19NO3/c1-12(2)10-17-16(18)6-4-3-5-13-7-8-14-15(9-13)20-11-19-14/h3-9,12H,10-11H2,1-2H3,(H,17,18)/b5-3+,6-4+",
    InChIKey: "WHAAPCGHVWVUEX-GGWOSOGESA-N",
    PubChemCID: 5320621,
    MDLNumber: "MFCD15146947",
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 48,
    Image: "/products/Psoralen-66-97-7.jpg",
    Product: "Psoralen",
    BotanicalSource: "Psoralea corylifolia",
    IUPACName: "7H-furo[3,2-g][1]benzopyran-7-one",
    Synonyms: "Ficusin",
    CASNumber: "66-97-7",
    MolecularFormula: "C11H6O3",
    MolecularWeight: 186.2,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C=CC2=CC3=C(OC=C3)C=C2O1",
    InChICode:
      "InChI=1S/C11H6O3/c12-11-2-1-7-5-8-3-4-13-9(8)6-10(7)14-11/h1-6H",
    InChIKey: "ZCCUUQDIBDJBTK-UHFFFAOYSA-N",
    PubChemCID: 6199,
    MDLNumber: "MFCD00010520",
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 49,
    Image: "/products/Pterostilbene-537-42-8.jpg",
    Product: "Pterostilbene",
    BotanicalSource: "Pterocarpus marsupium",
    IUPACName: "4-[(1E)-2-(3,5-dimethoxyphenyl)ethenyl]-phenol",
    Synonyms: "trans-3,5-Dimethoxy-4'-Hydroxystilbene",
    CASNumber: "537-42-8",
    MolecularFormula: "C16H16O3",
    MolecularWeight: 256.3,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COc1cc(/C=C\\c2ccc(O)cc2)cc(OC)c1",
    InChICode:
      "InChI=1S/C16H16O3/c1-18-15-9-13(10-16(11-15)19-2)4-3-12-5-7-14(17)8-6-12/h3-11,17H,1-2H3/b4-3+",
    InChIKey: "VLEUZFDZJKSGMX-ONEGZZNKSA-N",
    PubChemCID: 5281727,
    MDLNumber: "MFCD00238710",
    InventoryStatus: "Available in gram quantities",
    type: "analogs",
  },
  {
    id: 50,
    Image: "/products/Rhein-478-43-3.jpg",
    Product: "Rhein",
    BotanicalSource: "Aloe barbadensis",
    IUPACName:
      "9,10-dihydro-4,5-dihydroxy-9,10-dioxo-2-anthracenecarboxylic acid",
    Synonyms: "Rheic Acid",
    CASNumber: "478-43-3",
    MolecularFormula: "C15H8O6",
    MolecularWeight: 284.22,
    Appearance: "Crystalline solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C2=C(C=C(C(O)=O)C=C2O1)C(C3=CC=CC(O)=C31)=O",
    InChICode:
      "InChI=1S/C15H8O6/c16-9-3-1-2-7-11(9)14(19)12-8(13(7)18)4-6(15(20)21)5-10(12)17/h1-5,16-17H,(H,20,21)",
    InChIKey: "FCDLCPWAQCPTKC-UHFFFAOYSA-N",
    PubChemCID: 10168,
    MDLNumber: "MFCD00009618",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 51,
    Image: "/products/Rubiadin-117-02-2.jpg",
    Product: "Rubiadin",
    BotanicalSource: "Rubia cordifolia",
    IUPACName: "1,3-dihydroxy-2-methyl-9,10-anthracenedione",
    Synonyms: "C.I. 75350",
    CASNumber: "117-02-2",
    MolecularFormula: "C15H10O4",
    MolecularWeight: 254.24,
    Appearance: "Yellow colored solid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1C2=C(O)C(C)=C(O)C=C2C(C3=C1C=CC=C3)=O",
    InChICode:
      "InChI=1S/C15H10O4/c1-7-11(16)6-10-12(13(7)17)15(19)9-5-3-2-4-8(9)14(10)18/h2-6,16-17H,1H3",
    InChIKey: "IRZTUXPRIUZXMP-UHFFFAOYSA-N",
    PubChemCID: 124062,
    MDLNumber: "MFCD02752095",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 52,
    Image: "/products/Sesamin-0607-80-7.jpg",
    Product: "Sesamin",
    BotanicalSource: "Sesamum indicum",
    IUPACName:
      "[1S-(1α,3aα,4α,6aα)]-5,5'-(tetrahydro-1H,3H-furo[3,4-c]furan-1,4-diyl)bis-1,3-benzodioxole",
    Synonyms: "Fagarol",
    CASNumber: "607-80-7",
    MolecularFormula: "C20H18O6",
    MolecularWeight: 354.4,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "C1OC2=CC=[C@H](C=C2O1)[C@H]1OC[C@@H]2[C@H](OC[C@H]12)c1ccc2OCOc2c1",
    InChICode:
      "InChI=1S/C20H18O6/c1-3-15-17(25-9-23-15)5-11(1)19-13-7-22-20(14(13)8-21-19)12-2-4-16-18(6-12)26-10-24-16/h1-6,13-14,19-20H,7-10H2/t13-,14-,19+,20+/m0/s1",
    InChIKey: "PEYUIKBAABKQKQ-AFHBHXEDSA-N",
    PubChemCID: 72307,
    MDLNumber: "MFCD00216134",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 53,
    Image: "/products/Tetrahydrocurcumin-36062-04-1.jpg",
    Product: "Tetrahydrocurcumin",
    BotanicalSource: "Curcuma longa",
    IUPACName: "1,7-bis(4-hydroxy-3-methoxyphenyl)-3,5-heptanedione",
    Synonyms: "NSC 687845",
    CASNumber: "36062-04-1",
    MolecularFormula: "C21H24O6",
    MolecularWeight: 372.41,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC1=C(OC)C=C(CCC(CC(CCC2=CC=C(O)C(OC)=C2)=O)=O)C=C1",
    InChICode:
      "InChI=1S/C21H24O6/c1-26-20-11-14(5-9-18(20)24)3-7-16(22)13-17(23)8-4-15-6-10-19(25)21(12-15)27-2/h5-6,9-12,24-25H,3-4,7-8,13H2,1-2H3",
    InChIKey: "LBTVHXHERHESKG-UHFFFAOYSA-N",
    PubChemCID: 124072,
    MDLNumber: "MFCD04152347",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 54,
    Image: "/products/Tetrahydropiperin-23434-88-0.jpg",
    Product: "Tetrahydropiperin",
    BotanicalSource: "Piper nigrum",
    IUPACName: "5-(1,3-benzodioxol-5-yl)-1-(1-piperidinyl)-1-pentanone",
    Synonyms: "THP",
    CASNumber: "23434-88-0",
    MolecularFormula: "C17H23NO3",
    MolecularWeight: 289.37,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C(CCCCC1=CC(OCO2)=C2C=C1)N3CCCCC3",
    InChICode:
      "InChI=1S/C17H23NO3/c19-17(18-10-4-1-5-11-18)7-3-2-6-14-8-9-15-16(12-14)21-13-20-15/h8-9,12H,1-7,10-11,13H2",
    InChIKey: "APZYKUZPJCQGPP-UHFFFAOYSA-N",
    PubChemCID: 581676,
    MDLNumber: "MFCD17167032",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 55,
    Image: "/products/Vasicine-6159-55-3.jpg",
    Product: "Vasicine",
    BotanicalSource: "Adhatoda vasica",
    IUPACName: "(3S)-1,2,3,9-Tetrahydropyrrolo[2,1-b]quinazolin-3-ol",
    Synonyms: "(−)-Peganine",
    CASNumber: "6159-55-3",
    MolecularFormula: "C11H12N2O",
    MolecularWeight: 188.2,
    Appearance: "Off white soild",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O[C@H]1CCN2C1=NC(C=CC=C3)=C3C2",
    InChICode:
      "InChI=1S/C11H12N2O/c14-9-5-6-13-10(9)12-8-4-2-1-3-7(8)11(13)15/h1-4,10,14H,5-6H2/t10-/m0/s1",
    InChIKey: "YIICVSCAKJMMDJ-JTQLQIEISA-N",
    PubChemCID: 442929,
    MDLNumber: "MFCD09028068",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 56,
    Image: "/products/Vasicinone-486-64-6.jpg",
    Product: "Vasicinone",
    BotanicalSource: "Adhatoda vasica",
    IUPACName: "(3S)-2,3-Dihydro-3-hydroxypyrrolo[2,1-b]quinazolin-9(1H)-one",
    Synonyms: "(-)Vasicinone",
    CASNumber: "486-64-6",
    MolecularFormula: "C11H10N2O2",
    MolecularWeight: 202.2,
    Appearance: "Off white powder",
    Purity: ">96",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "O=C1N2C([C@@H](O)CC2)=NC3=CC=CC=C31",
    InChICode:
      "1S/C11H10N2O2/c14-9-5-6-13-10(9)12-8-4-2-1-3-7(8)11(13)15/h1-4,9,14H,5-6H2/t9-/m0/s1",
    InChIKey: "SDIVYZXRQHWCKF-VIFPVBQESA-N",
    PubChemCID: 10242,
    MDLNumber: "MFCD11519457",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 57,
    Image: "/products/WithaferinA-5119-48-2.jpg",
    Product: "Withaferin A",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "4β,5β,6β,22R)-5,6-Epoxy-4,22,27-trihydroxy-1-oxo-ergosta-2,24-dien-26-oic acid δ-lactone",
    Synonyms: [
      "NSC 101088",
      "NSC-101088",
      "NSC101088",
      "NSC 273757",
      "NSC-273757",
      "NSC273757.",
    ],
    CASNumber: "5119-48-2",
    MolecularFormula: "C28H38O6",
    MolecularWeight: 470.6,
    Appearance: "White crystalline powder",
    Purity: ">98%",
    HRMS_ESI: "m/z 471.45 [M]+",
    SpecificRotation_aD20: null,
    SMILES:
      "O=C/1O[C@H](CC(=C\\1CO)\\C)[C@@H](C)[C@H]6CC[C@@H]4[C@]6(C)CC[C@@H]3[C@]5(C(=O)\\C=C\\[C@H](O)[C@]52O[C@@H]2C[C@H]34)C",
    InChICode:
      "InChI=1S/C28H38O6/c1-14-11-21(33-25(32)17(14)13-29)15(2)18-5-6-19-16-12-24-28(34-24)23(31)8-7-22(30)27(28,4)20(16)9-10-26(18,19)3/h7-8,15-16,18-21,23-24,29,31H,5-6,9-13H2,1-4H3/t15-,16-,18+,19-,20+,22-,23-,24+,26+,27-,28-/m0/s1",
    InChIKey: "DBRXOUCRJQVYJQ-NPRZOXALSA-N",
    PubChemCID: 45489105,
    MDLNumber: "MFCD10687098",
    InventoryStatus: "Available in gram quantities",
    type: "lead_analogs",
  },
  {
    id: 58,
    Image: "/products/WithanolideA-32911-62-9.jpg",
    Product: "Withanolide A",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "(5α,6α,7α,22R)-6,7-epoxy-5,20,22-trihydroxy-1-oxo-ergosta-2,24-dien-26-oic Acid δ-Lactone",
    Synonyms: ["Withaniol"],
    CASNumber: "32911-62-9",
    MolecularFormula: "C28H38O6",
    MolecularWeight: 470.6,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C(O1)C(C)=C(C)C[C@]1([H])[C@@](O)(C)[C@H]2CC[C@@]3([H])[C@]4([H])[C@H]5[C@H](O5)[C@@]([C@@]6(C)[C@@]4([H])CC[C@@]32C)(O)CC=CC6=O",
    InChICode:
      "InChI=1S/C28H38O6/c1-14-13-20(32-25(30)15(14)2)16(3)27(31)12-9-17-21-18(8-11-25(17,27)4)26(5)20(16)7-6-10-28(26,32)23-22(21)34-23/h6-7,16-20,22-23,31-32H,8-13H2,1-5H3/t16-,17+,18-,19-,20+,22-,23-,24-,26+,27-,28-/m0/s1DXWHOKCXBGLTMQ-SFQAJKIESA-N",
    InChIKey: "5H3/t16-,17-,18-,20+,21-,22-,23-,25-,26-,27+,28-/m0/s1",
    PubChemCID: 11294368,
    MDLNumber: "MFCD07779143",
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 59,
    Image: "/products/WithanolideB-56973-41-2.jpg",
    Product: "Withanolide B",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "6α,7α-epoxy-5α,22R-dihydroxy-1-oxo-ergosta-2,24-dien-26-oic acid, δ-lactone",
    Synonyms: [],
    CASNumber: "56973-41-2",
    MolecularFormula: "C28H38O5",
    MolecularWeight: 454.6,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1[C@@]2(C)[C@]([C@@H](O3)[C@@H]3[C@]4([H])[C@]2([H])CC[C@@]5(C)[C@@]4([H])CC[C@@H]5[C@H](C)[C@@]6(OC(C(C)=C(C)C6)=O)[H])(O)CC=C1",
    InChICode:
      "InChI=1S/C28H38O5/c1-14-13-19(33-24(30)15(14)2)16(3)27(31)12-9-17-21-18(8-11-25(17,27)4)26(5)20(29)7-6-10-28(26,32)23-22(21)34-23/h6-7,16-19,21-23,31-32H,8-13H2,1-5H3/t16-,17+,18+,19-,21+,22+,23+,25+,26+,27+,28+/m1/s1",
    InChIKey: "ZTEVDTFJUUJBLP-MBMSZCMESA-N",
    PubChemCID: 14236711,
    MDLNumber: "MFCD19053156",
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 60,
    Image: "/products/Withanone-27570-38-3.jpg",
    Product: "Withanone",
    BotanicalSource: "Withania somnifera",
    IUPACName:
      "(22R)-6α,7α-Epoxy-5,17,22-trihydroxy-1-oxo-5α-ergosta-2,24-dien-26-oic acid δ-lactone",
    Synonyms: ["NSC 179884"],
    CASNumber: "27570-38-3",
    MolecularFormula: "C28H38O6",
    MolecularWeight: 470.6,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "O=C1C=CC[C@]2(O)[C@@]3([H])O[C@@]3([H])[C@@]4([H])[C@H]5[C@H](O5)[C@@]([C@@]6(C)[C@@]4([H])CC[C@@]32C)(O)CC=CC6=O",
    InChICode:
      "InChI=1S/C28H38O6/c1-14-13-19(33-24(30)15(14)2)16(3)17-8-9-18-22-19(10-12-26(17,18)4)27(5)21(29)7-6-11-28(27,31)24-23(22)33-24/h6-7,16-19,21-23,31-32H,8-13H2,1-5H3/t16-,17+,18+,19-,21+,22+,23+,25-,26-,27+,28+/m1/s1",
    InChIKey: "FAZIYUIDUNHZRG-PCTWTJKKSA-N",
    PubChemCID: 21679027,
    MDLNumber: "MFCD09264639",
    InventoryStatus: "Available in gram quantities",
    type: "lead",
  },
  {
    id: 61,
    Image: null,
    Product: "Acetyl boswellic acids (α+β)",
    BotanicalSource: "Boswellia serrata",
    IUPACName: null,
    Synonyms: null,
    CASNumber: "89913-60-0(α) 5968-70-7(β)",
    MolecularFormula: "C32H50O4",
    MolecularWeight: 498.7,
    Appearance: "Off White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in gram quantities",
  },
  {
    id: 62,
    Image: "/products/Betulin-473-98-3.jpg",
    Product: "Betulin",
    BotanicalSource: "Betula alba",
    IUPACName:
      "(1R,3aS,5aR,5bR,7aR,9S,11aR,11bR,13aR,13bR)-3a-(Hydroxymethyl)-5a,5b,8,8,11a-pentamethyl-1-prop-1-en-2-yl-1,2,3,4,5,6,7,7a,9,10,11,11b,12,13,13a,13b-hexadecahydrocyclopenta[a]chrysen-9-ol",
    Synonyms: "Betulinol",
    CASNumber: "473-98-3",
    MolecularFormula: "C30H50O2",
    MolecularWeight: 442.72,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[H][C@]12CC[C@]3([H])[C@@]4(C)CC[C@H](O)C(C)(C)[C@]4([H])CC[C@@]3(C)[C@]1(C)CC[C@@]5(CO)CC[C@@H](C(C)=C)[C@]25[H]",
    InChICode:
      "1S/C30H50O2/c1-19(2)20-10-15-30(18-31)17-16-28(6)21(25(20)30)8-9-23-27(5)13-12-24(32)26(3,4)22(27)11-14-29(23,28)7/h20-25,31-32H,1,8-18H2,2-7H3/t20-,21+,22-,23+,24-,25+,27-,28+,29+,30+/m0/s1",
    InChIKey: "FVWJYYTZTCVBKE-ROUWMTJPSA-N",
    PubChemCID: "72326",
    MDLNumber: "MFCD00016802",
    InventoryStatus: "Available in milligrams",
    type: "lead_analogs",
  },
  {
    id: 63,
    Image: "/products/Betulinic-acid-472-15-1.jpg",
    Product: "Betulinic acid",
    BotanicalSource: "Betula alba",
    IUPACName:
      "(1R,3aS,5aR,5bR,7aR,9S,11aR,11bR,13aR,13bR)-9-hydroxy-5a,5b,8,8,11a-pentaMethyl-1-(prop-1-en-2-yl)icosahydro-1H-cyclopenta[a]chrysene-3a-carboxylic acid",
    Synonyms: "Lupatic acid",
    CASNumber: "472-15-1",
    MolecularFormula: "C30H48O3",
    MolecularWeight: 456.7,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "CC(=C)[C@@H]1CC[C@@]2(CC[C@]3(C)[C@H](CC[C@@H]4[C@@]5(C)CC[C@H](O)C(C)(C)[C@@H]5CC[C@@]34C)[C@@H]12)C(O)=O",
    InChICode:
      "1S/C30H48O3/c1-18(2)19-10-15-30(25(32)33)17-16-28(6)20(24(19)30)8-9-22-27(5)13-12-23(31)26(3,4)21(27)11-14-29(22,28)7/h19-24,31H,1,8-17H2,2-7H3,(H,32,33)/t19-,20+,21-,22+,23-,24+,27-,28+,29+,30-/m0/s1",
    InChIKey: "QGJZLNKBHJESQX-FZFNOLFKSA-N",
    PubChemCID: "64971",
    MDLNumber: "MFCD00009619",
    InventoryStatus: "Available in milligrams",
    type: "lead_analogs",
  },
  {
    id: 64,
    Image: null,
    Product: "Boswellic acids (α+β)",
    BotanicalSource: "Boswellia serrata",
    IUPACName: null,
    Synonyms: null,
    CASNumber: "471-66-9(α) 631-69-6(β)",
    MolecularFormula: "C30H48O3",
    MolecularWeight: 456.73,
    Appearance: "Off White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in milligrams",
  },
  {
    id: 65,
    Image: "/products/Carnosic-acid-3650-09-7.jpg",
    Product: "Carnosic acid",
    BotanicalSource: "Rosmarinus officinalis",
    IUPACName:
      "(4aR,10aS)-5,6-Dihydroxy-7-isopropyl-1,1-dimethyl-1,3,4,9,10,10a-hexahydro-2H-phenanthrene-4a-carboxylic acid",
    Synonyms: "Salvin",
    CASNumber: "3650-09-7",
    MolecularFormula: "C20H28O4",
    MolecularWeight: 332.43,
    Appearance: "Off White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC(C)c1cc2CC[C@H]3C(C)(C)CCC[C@]3(C(O)=O)c2c(O)c1O",
    InChICode:
      "1S/C20H28O4/c1-11(2)13-10-12-6-7-14-19(3,4)8-5-9-20(14,18(23)24)15(12)17(22)16(13)21/h10-11,14,21-22H,5-9H2,1-4H3,(H,23,24)/t14-,20+/m0/s1",
    InChIKey: "QRYRORQUOLYVBU-VBKZILBWSA-N",
    PubChemCID: 65126,
    MDLNumber: "MFCD02259459",
    InventoryStatus: "Available in milligrams",
    type: "lead",
  },
  {
    id: 66,
    Image: "/products/Carnosol-5957-80-2.jpg",
    Product: "Carnosol",
    BotanicalSource: "Rosmarinus officinalis",
    IUPACName:
      "(1R,8S,10S)-3,4-dihydroxy-11,11-dimethyl-5-propan-2-yl-16-oxatetracyclo[6.6.2.01,10.02,7]hexadeca-2,4,6-trien-15-one",
    Synonyms: "Picrosalvin",
    CASNumber: "5957-80-2",
    MolecularFormula: "C20H26O4",
    MolecularWeight: 330.42,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "CC(C)c1cc2[C@@H]3C[C@H]4C(C)(C)CCC[C@]4(C(=O)O3)c2c(O)c1O",
    InChICode:
      "1S/C20H26O4/c1-10(2)11-8-12-13-9-14-19(3,4)6-5-7-20(14,18(23)24-13)15(12)17(22)16(11)21/h8,10,13-14,21-22H,5-7,9H2,1-4H3/t13-,14-,20+/m0/s1",
    InChIKey: "XUSYGBPHQBWGAD-PJSUUKDQSA-N",
    PubChemCID: 442009,
    MDLNumber: "MFCD02752467.",
    InventoryStatus: "Available in milligrams",
    type: "lead",
  },
  {
    id: 67,
    Image: null,
    Product: "Guggulsterones (E+Z)",
    BotanicalSource: "Commiphora mukul",
    IUPACName: null,
    Synonyms: null,
    CASNumber: "39025-24-6(E) 39025-23-5(Z)",
    MolecularFormula: "C21H28O2",
    MolecularWeight: 312.45,
    Appearance: "Pale  yellow liquid",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: null,
    InChICode: null,
    InChIKey: null,
    PubChemCID: null,
    MDLNumber: null,
    InventoryStatus: "Available in milligrams",
  },
  {
    id: 68,
    Image: "/products/Rosmarinic-acid-20283-92-5.jpg",
    Product: "Rosmarinic acid",
    BotanicalSource: "Rosmarinus officinalis",
    IUPACName:
      "(R)-O-(3,4-Dihydroxycinnamoyl)-3-(3,4- dihydroxyphenyl)lactic acid",
    Synonyms: "Labiatenic acid",
    CASNumber: "20283-92-5",
    MolecularFormula: "C18H16O8",
    MolecularWeight: 360.31,
    Appearance: "Powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "OC(=O)[C@@H](Cc1ccc(O)c(O)c1)OC(=O)C=Cc2ccc(O)c(O)c2",
    InChICode:
      "1S/C18H16O8/c19-12-4-1-10(7-14(12)21)3-6-17(23)26-16(18(24)25)9-11-2-5-13(20)15(22)8-11/h1-8,16,19-22H,9H2,(H,24,25)/b6-3+/t16-/m1/s1",
    InChIKey: "DOUMFZQKYFQNTF-WUTVXBCWSA-N",
    PubChemCID: 5281792,
    MDLNumber: "MFCD00017740",
    InventoryStatus: "Available in milligrams",
    type: "lead_analogs",
  },
  {
    id: 69,
    Image: null,
    Product: "Wedelolactone",
    BotanicalSource: "Eclipta alba",
    IUPACName:
      "1,8,9-Trihydroxy-3-methoxy-6H-benzofuro[3,2-c][1]benzopyran-6-one",
    Synonyms: "7-Methoxy-5,11,12-trihydroxycoumestan",
    CASNumber: "524-12-9",
    MolecularFormula: "C16H10O7",
    MolecularWeight: 314.25,
    Appearance: "Off white powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES: "COc1cc(O)c-2c(OC(=O)c3c-2oc4cc(O)c(O)cc34)c1",
    InChICode:
      "1S/C16H10O7/c1-21-6-2-10(19)14-12(3-6)23-16(20)13-7-4-8(17)9(18)5-11(7)22-15(13)14/h2-5,17-19H,1H3",
    InChIKey: "XQDCKJKKMFWXGB-UHFFFAOYSA-N",
    PubChemCID: 5281813,
    MDLNumber: "MFCD07778564",
    InventoryStatus: "Available in milligrams",
  },
  {
    id: 70,
    Image: "/products/Ursolic-acid-77-52-1.jpg",
    Product: "Ursolic acid",
    BotanicalSource: "Oscimum sanctum",
    IUPACName: "3β-Hydroxy-12-ursen-28-ic acid",
    Synonyms: "Bungeolic Acid",
    CASNumber: "77-52-1",
    MolecularFormula: "C30H48O3",
    MolecularWeight: 456.7,
    Appearance: "White powder",
    Purity: ">98%",
    HRMS_ESI: null,
    SpecificRotation_aD20: null,
    SMILES:
      "[H][C@@]12CC[C@]3(C)[C@]([H])(CC=C4[C@]5([H])[C@@H](C)[C@H](C)CC[C@@]5(CC[C@@]34C)C(O)=O)[C@@]1(C)CC[C@H](O)C2(C)C",
    InChICode:
      "1S/C30H48O3/c1-18-10-15-30(25(32)33)17-16-28(6)20(24(30)19(18)2)8-9-22-27(5)13-12-23(31)26(3,4)21(27)11-14-29(22,28)7/h8,18-19,21-24,31H,9-17H2,1-7H3,(H,32,33)/t18-,19+,21+,22-,23+,24+,27+,28-,29-,30+/m1/s1",
    InChIKey: "WCGUUGGRBIKTOS-GPOJBZKASA-N",
    PubChemCID: 64945,
    MDLNumber: "MFCD00009621",
    InventoryStatus: "Available in milligrams",
    type: "lead_analogs",
  },
];

export default phytochemicals;
